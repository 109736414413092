<template>
  <div>
    <BaseIcon
      name="shopping-cart"
      width="25"
      height="25"
      class="my-1 mr-2"
      style="float: right"
    />
    <h3>{{ "Bezahlen" | translate }}</h3>
    <div v-show="tryAgain" class="cart panel p-2">
      <div class="panel t1 p-2">
        <div class="text-center">
          <img src="/assets/img/error.png" style="width: 50px;" /> {{ $t("payment_error") }}
        </div>
        <div class="row mx-1 d-flex justify-content-between my-1">
          <div class="col-lg-12 m-0 text-center">
            <button
              v-show="tryAgain"
              class="btn btn-lg btn-danger m-0"
              @click="checkout()"
            >
              {{ "Wiederholen" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="paySuccess" class="cart panel p-2">
      <div class="panel t1 p-2 text-center">
        <h2>{{ "Vielen Dank" | translate }}</h2>
        {{ $t("payment_success1") }}
        <ul style="list-style-position: inside;margin-left:-50px;">
          <li><em>{{"Betrag" | translate}}</em>: <strong>{{ total | currency(cSymb) }}</strong></li>
          <li><em>{{"Bestell Nr" | translate}}</em>: <strong>{{paymentId}}</strong></li>
        </ul>
        {{ $t("payment_success3") }}
        <div class="row mx-1 d-flex justify-content-between my-1">
            <div class="m-0">
              <button class="btn btn-lg btn-primary m-0" @click="orders()">
                {{ $t("Bestellungen") }}
              </button>
            </div>
            <div class="m-0 p-0">
              <button class="btn btn-lg btn-primary m-0" @click="home()">
                {{ $t("Zur Karte") }}
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "app-paymentsuccess",
  components: {},
  data() {
    return {
      paymentStatus: "",
      paySuccess: false,
      spinner: false,
      tryAgain: false,
    };
  },
  computed: {
    ...mapGetters(["orderData", "total", "companyData", "userData"]),
    AllowHobex() {
      if (this.companyData.pspType == 2) {
        return true;
      } else {
        return false;
      }
    },
    AllowWalle() {
      if (this.companyData.pspType == 3) {
        return true;
      } else {
        return false;
      }
    },
    TableToken() {
      return this.userData.token;
    },
     cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    total() {
      return this.orderData.total;
    },
    paymentId() {
      if (this.AllowHobex) {
        return this.orderData.transactionReference;
      }else if (this.AllowWalle){
        return this.orderData.walleeTid;
      }else{
        return "000";
      }
    }

  },
  methods: {
    ...mapActions(["removeAllFromCart", "clearOrder"]),
    sendOrder() {
      DataService.createOrder(this.orderData, this.TableToken);
      this.removeAllFromCart();
      this.clearOrder();
    },
    checkout() {
      this.$router.push({ path: "/checkout" });
    },
    home() {
      this.$router.push({ name: "order" });
    },
    orders() {
      this.$router.push({ name: "orders" });
    },
  },
  created() {
    if (this.AllowHobex) {
      DataService.PaymentStatus(
        this.orderData.transactionReference,
        this.userData.token,
        this.companyData.apiKey
      ).then((response) => {
        this.paymentStatus = response.data.status;
        if (response.data.status === "Success") {
          this.paySuccess = true;
          this.spinner = true;
          this.tryAgain = false;
          this.sendOrder();
        } else {
          this.paySuccess = false;
          this.spinner = false;
          this.tryAgain = true;
        }
        console.log(response.data);
      });
    };
    if (this.AllowWalle) {
      DataService.PaymentStatus(
        this.orderData.walleeTid,
        this.userData.token,
        this.companyData.apiKey
      ).then((response) => {
        this.paymentStatus = response.data.status;
        if (response.data.status === "Success") {
          this.paySuccess = true;
          this.spinner = true;
          this.tryAgain = false;
          this.sendOrder();
        } else {
          this.paySuccess = false;
          this.spinner = false;
          this.tryAgain = true;
        }
        console.log(response.data);
      });
    }
  },
};
</script>
